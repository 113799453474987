import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import { ErrorBoundary } from "react-error-boundary";
// import ErrorFallback from "./components/ErrorFallback";
import { lazy, Suspense } from "react";
import { useAuthContext } from "./hooks/useAuthContext";
import Loading from "./components/Loading";
import NotFound from "./pages/notFound/NotFound";
import ErrorBoundary from "./components/ErrorBoundary";
import ProtectedRoute from "./components/ProtectedRoute";
//import ConnectionStatus from "./components/ConnectionStatus";

const Message = lazy(() => import("./pages/message/Message"));
const Layout = lazy(() => import("./components/layout/Layout"));
const Club = lazy(() => import("./pages/club/club/Club"));
const Profile = lazy(() => import("./pages/profile/profile/Profile"));
const EmbedMiluz = lazy(() => import("./pages/emberdedMiluz/EmbedMiluz"));
const ForgotPassword = lazy(() =>
  import("./pages/forgotPassword/ForgotPassword")
);
const Login = lazy(() => import("./pages/login/Login"));
const Signup = lazy(() => import("./pages/signup/Signup"));
const Notification = lazy(() => import("./pages/notification/Notification"));
const Door = lazy(() => import("./pages/doorstatus/door/Door"));
const Light = lazy(() => import("./pages/lightstatus/light/Light"));
const ResetPassword = lazy(() => import("./pages/resetPassword/ResetPassword"));
const ManagerCode = lazy(() =>
  import("./pages/managerCodes/managerCode/ManagerCode")
);
const WhatsApp = lazy(() => import("./pages/whatsApp/whatsApp/WhatsApp"));
const Roles = lazy(() => import("./pages/profile/roles/Roles"));
const UserRoles = lazy(() => import("./pages/profile/userRoles/UserRoles"));

const ProfileDetails = lazy(() =>
  import("./pages/profile/profileDetails/ProfileDetails")
);
const Password = lazy(() => import("./pages/profile/password/Password"));

const MiluzProfile = lazy(() => import("./pages/profile/miluz/MiluzLogin"));
const WhatsappQR = lazy(() => import("./pages/profile/whatsapp/Whatsapp"));

const ClubData = lazy(() => import("./pages/club/clubDataTable/ClubData"));
const CourtCalendar = lazy(() =>
  import("./pages/club/courtDetails/viewCourts/CourtCalendar")
);
//testing
const WhatsappChatBot = lazy(() =>
  import("./pages/profile/whatsappChatBot/WhatsappChatBot")
);

function App() {
  const { user } = useAuthContext();
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Routes>
          <Route
            index
            element={user ? <Navigate to="/club" /> : <Navigate to="/login" />}
          />
          <Route
            path="login"
            element={
              !user ? (
                <Suspense fallback={<Loading />}>
                  <Login />
                </Suspense>
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="signup"
            element={
              !user ? (
                <Suspense fallback={<Loading />}>
                  <Signup />
                </Suspense>
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="reset-password"
            element={
              !user ? (
                <Suspense fallback={<Loading />}>
                  <ForgotPassword />
                </Suspense>
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="reset-password/:token"
            element={
              !user ? (
                <Suspense fallback={<Loading />}>
                  <ResetPassword />
                </Suspense>
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/"
            element={
              <Suspense fallback={<Loading />}>
                <Layout />
              </Suspense>
            }
          >
            {user ? (
              <>
                <Route
                  path="club"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Club />
                    </Suspense>
                  }
                >
                  <Route index element={<ClubData />} />
                  <Route
                    path="court-calendar/:rowId"
                    element={
                      <ProtectedRoute
                        element={CourtCalendar}
                        requiredPermission="manage_reservations"
                      />
                    }
                  />
                </Route>

                <Route path="light-state">
                  <Route
                    index
                    element={
                      <ProtectedRoute
                        element={Light}
                        requiredPermission="manage_lights"
                      />
                    }
                  />
                </Route>
                <Route path="doorstate">
                  <Route
                    index
                    element={
                      <ProtectedRoute
                        element={Door}
                        requiredPermission="manage_doors"
                      />
                    }
                  />
                </Route>
                <Route path="messages">
                  <Route
                    index
                    element={
                      <Suspense fallback={<Loading />}>
                        <Message />
                      </Suspense>
                    }
                  />
                </Route>
                <Route path="notifications">
                  <Route
                    index
                    element={
                      <Suspense fallback={<Loading />}>
                        <Notification />
                      </Suspense>
                    }
                  />
                </Route>
                <Route path="manager_codes">
                  <Route
                    index
                    element={
                      user ? (
                        <ProtectedRoute
                          element={ManagerCode}
                          requiredPermission="manage_doors"
                        />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                  />
                </Route>

                <Route path="whatsapp">
                  <Route
                    index
                    element={
                      user ? (
                        <ProtectedRoute
                          element={WhatsApp}
                          requiredPermission="manage_whatsapp"
                        />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                  />
                </Route>

                <Route path="embed_miluz">
                  <Route
                    index
                    element={
                      <ProtectedRoute
                        element={EmbedMiluz}
                        requiredPermission="manage_miluz"
                      />
                    }
                  />
                </Route>

                <Route
                  path="profile"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Profile />
                    </Suspense>
                  }
                >
                  <Route index element={<ProfileDetails />} />
                  <Route path="reset-password" element={<Password />} />
                  <Route
                    path="miluz"
                    element={
                      <ProtectedRoute
                        element={MiluzProfile}
                        requiredPermission="manage_miluz"
                      />
                    }
                  />
                  <Route
                    path="whatsappQR"
                    element={
                      <ProtectedRoute
                        element={WhatsappQR}
                        requiredPermission="manage_whatsapp"
                      />
                    }
                  />
                  <Route
                    path="roles"
                    element={
                      <ProtectedRoute
                        element={Roles}
                        requiredPermission="manage_settings"
                      />
                    }
                  />
                  <Route
                    path="user-roles"
                    element={
                      <ProtectedRoute
                        element={UserRoles}
                        requiredPermission="manage_settings"
                      />
                    }
                  />
                  <Route
                    path="whatsappChatBot"
                    element={
                      <ProtectedRoute
                        element={WhatsappChatBot}
                        requiredPermission="manage_whatsapp"
                      />
                    }
                  />
                </Route>
              </>
            ) : (
              <Route path="/" element={<Navigate to="/login" replace />} />
            )}
            {/* Catch-all route for 404 page */}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;